.Contact {
  background: url("../../assets/images/Slice1.svg");
  height: auto;
  background-size: cover;

  .footerWeb {
    display: block;
  }

  .footerMp {
    display: none;
  }

  .webs {
    display: none;
  }

  .websBac {
    display: block;
    width: 100%;
    height: 49.2rem;
    background: url("../../assets/images/website/Slice2.png") no-repeat center center !important;
  }
  .websBac2 {
    display: block;
    width: 100%;
    height: 35.25rem;
    background: url("../../assets/images/website/Slice3.png") no-repeat center center !important;
  }

  .vector {
    width: 56.2%;
    height: 21.125rem;
    //background: #F8FAE4;
  }

  .fansSay {
    width: 27.3%;
    //height: 27.3%;

    .fansSayTit {
      width: 86%;
      font-weight: 400;
      font-size: 2rem;

      //line-height: 45px;
      /* identical to box height */


      /* BR_Brand2/05 */

      color: #F8FAE4;





    }

    .hospitality {

    }

    .fansSayTxt {
      font-family: "MFJianHei", serif;
      color: #D7524E;
      font-size: 3.125rem;
      text-underline-offset: 16px;
    }
  }

  .redR {
    width: 11.5rem;
    height: 11.5rem;
    background: #D7524E;

    .formula {
      font-family: "MFJianHei", serif;
      color: #F8FAE4;
      font-size: 2.79rem;
    }

    .formulaF {
      //width: 90%;
      //height: 90%;
    }
  }

  .plus {
    color: #D7524E;
  }

  .lastWord {
    color: #F8FAE4;
    font-size: 2rem;
  }

  .lasts {
    /* BR_Brand2/00 */

    color: #F8A03F;
    //font-size: 2rem;
    font-weight: 600;
  }

  .qrcode {
    margin-top: 11.75rem;
  }

  .buyOnLine {
    color: #F8A03F;
    font-size: 2rem;
  }

  .onlineLogo {
    width: 6.25rem;
    height: 6.25rem;
    //display: flex;
    //align-items: center;


  }

  .onlineLogoName {
    color: #F8FAE4;
    font-size: 1rem;
    font-weight: 400;
  }

  .searchUs {
    width: 30%;
    height: 4.625rem;
    margin-top: 3.125rem;
    .searchUsInput {
      width: 80%;
      background: #F8FAE4;
    }
  }
}

.attention {
  color: #D7524E;
  font-size: 1.5rem;
}

.MFJianHei {
  font-family: "MFJianHei", serif;
}

.SentinelType {
  font-family: "SentinelType", serif;
}

.address {
  width: 23%;
  font-size: 1.5rem;
  color: #FDFAE0;
  margin-top: 4.625rem;

  .addressTxt {
    font-size: 1rem;
    font-weight: 400;
  }
}

.footers {
  width: 56.25%;
  color: #F8FAE4;
  margin-top: 4.625rem;
  padding-bottom: 30px;
  //visibility: hidden;
}


@media screen and (max-width: 576px) {
  .Contact {
    background: url("../../assets/images/Slice1.svg") no-repeat ;
    height: auto;
    min-height: auto;
    background-size: cover;

    .footerWeb {
      display: none;
    }

    .footerMp {
      display: block;
      margin-top: -1px;

      .links {
        top: 17rem;
        //left: 8rem;
      }
    }

    .webs {
      display: block;
    }

    .websBac {
      display: none;
    }

    .websBac2 {
      display: none;
    }
  }

  .qinLogo {
    width: 8.125rem;
    padding-top: 2.2rem;
  }

  .mps {
    width: 20.625rem;
    font-size: 1rem;
  }

  .vector {
    width: 100% !important;
    height: 8.875rem !important;
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      width: 90%;
    }

    img {


    }
  }

  .fansSay {
    width: 14.5rem !important;
    height: 20rem !important;


    .fansSayTit {
      width: 12.25rem !important;
      font-size: 14px !important;
    }

    .fansSayTxt {
      font-size: 1.25rem !important;
    }
  }

  .mPP {
    width: 19.75rem;
    height: 5.825rem;
    margin: 2rem auto 0;
  }

  .redR {
    width: 4.8rem !important;
    height: 4.8rem !important;

    .formula {
      font-family: "MFJianHei", serif;
      color: #F8FAE4;
      font-size: 1.25rem !important;
    }
  }

  .plus {
    color: #D7524E;
    width: 1.17rem !important;
    height: 1.17rem !important;
    margin-left: 1rem;
    margin-right: 1rem ;
  }

  .mQinLogo {
    width: 5.375rem;
    margin-top: 5rem;
  }

  .lastWord {
    color: #F8FAE4;
    width: 18.4rem;
    font-size: 1rem !important;

    .lasts {
      font-size: 1rem;
    }
  }

  .qrcode {
    margin-top: 3.625rem !important;
    img {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  .buyOnLine {
    margin-top: 1rem !important;
    font-size: 1.375rem !important;
  }

  .buyOnLineM {
    margin-top: 3rem !important;
    font-size: 1.375rem !important;
  }

  .mF {
    width: 21.875rem;
    height: 4.7rem;
    margin: 0 auto;
    margin-top: 3rem !important;
  }

  .onlineLogo {
    width: 3.125rem !important;
    height: 3.125rem !important;
    img {

      margin: 0 auto;
    }
    //margin-right: 1.5rem !important;
  }

  .logoF {
    margin-right: 0.75rem;

    .onlineLogo {
      img {
        width: 3.125rem !important;
        height: 3.125rem !important;
        margin: 0 auto;
      }
      //margin-right: 1.5rem !important;
    }
  }

  .onlineLogoName {
    font-size: 0.75rem !important;
  }

  .searchUs {
    width: 14.875rem !important;
    height: 2.25rem !important;
  }

  .searchWord {
    width: 2.125rem !important;
    font-size: 1rem !important;
    margin-right: 0.5625rem;
    margin-top: 0 !important;
  }

  .searchUsText {
    font-size: 1.125rem !important;
  }

  .searchIcon {
    width: 1.25rem;
    height: 1.25rem;
  }

  .attentionDom {
    width: 16rem !important;
    margin-top: 2.125rem !important;

    .attention {
      font-size: 10px !important;

    }
  }

  .address {
    margin-top: 2.5rem;
    width: 20rem;
  }

  .addressTxt {
    font-size: 0.75rem !important;
  }

  .footers {
    width: 100%;

    .mbtxt1 {
      width: 117px;
    }
    .mbtxt2 {
      width: 112px;
    }
    .mbtxt3 {
      width: 88px;
    }

    div {
      font-size: 12px !important;
    }
  }
}

