@tailwind base;
@tailwind components;
@tailwind utilities;



*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    /*overflow-x: hidden;*/
}

@font-face {
    font-family: "MFJianHei";
    src: url('./assets/font/MFJianHei_Noncommercial-Regular.ttf');
}

@font-face {
    font-family: "SentinelType";
    src: url('./assets/font/SentinelType-BigNoodleTitling.otf');
}