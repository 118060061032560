.qinBeer {
  background: url("../../assets/images/website/Slice_1.jpg") no-repeat center center !important;
  height: 67.5rem;
  background-size: cover;
  //background-size: 100% 100%;
}

.title {
  width: 30rem;
  height: 26rem;
  visibility: hidden;
}

.lines {
  background: #121D4E;
}

.CraftBrewingTitles {
  width: 56%;
  visibility: hidden;
}

.CraftBrewingCarousels {
  width: 56rem;
  height: 25.125rem;
  //border: 2px solid #F8FAE4;
  //box-shadow: 0 2px 3px #BFCEF4;
  border-radius: 20px;


  .ant-carousel {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slider {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
  }

  .slick-slide:first-child {
    height: 100%;
  }

  .Carousels {
    height: 25.125rem;
  }
}

.wine {
  width: 9.125rem;
  height: 23.2rem;
}

.CarouselWeb {
  display: flex;
}

.CarouselMP {
  display: none;
}

@media screen and (max-width: 576px){
  .CarouselWeb {
    display: none;
  }

  .CarouselMP {
    display: block;
  }

  .qinBeer {
    background: url("../../assets/images/Slice11.png") no-repeat !important;
    height: 39.6rem;
    min-height: auto;
    background-size: 100% 100% !important;
    //background-size: 100% 100%;
  }

  .title {
    width: 23rem;
    height: 20rem;
  }

  .qinSerise {
    width: 6.3125rem;
  }

  .lines {
    width: 1.75rem;
    height: 0.125rem;
    background: #121D4E;
  }

  .CraftBrewingCarousels {
    width: 20.625rem;
    height: 10rem;
    margin-left: 2rem;
    margin-top: 1rem;

    .Carousels {
      height: 10rem;

      .wine {
        width: 2.625rem;
        display: flex;
        align-items: center;
      }


      .contentImg {
        width: 14.375rem;
      }


    }
  }
}

