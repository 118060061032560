.CraftBrewingTitle {
  width: 56%;

  .line {
    background: #F8A03F;

  }


}

.CraftBrewingCarousel {
  width: 56rem;
  height: 25.125rem;
  //border: 2px solid #F8A03F;
  //box-shadow: 0 2px 3px #BFCEF4;
  border-radius: 20px;


  .ant-carousel {
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
  .slick-slider {
    height: 100%;
  }
  .slick-list {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
  }

  .slick-slide:first-child {
    height: 100%;
  }

  .Carousels {
    height: 25.125rem;
  }
}

.leftSlide {
  font-size: 1.5rem;
  //width: 60.875rem;
}

.arrows {
  span {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.CarouselWeb {
  display: flex;
}

.CarouselMP {
  display: none;
}


@media screen and (max-width: 576px) {

  .CarouselWeb {
    display: none;
  }

  .CarouselMP {
    display: block;
  }

  .CraftBrewing {
    visibility: hidden;
  }
  .CraftBrewingDom {
    width: 100%;
    height: 321px;

    background: url("../../assets/images/CraftBrewingDom.png") no-repeat  !important;
    background-size: 100% 100% !important;
  }

  .line {
    width: 1.75rem;
    height: 0.125rem;
  }

  .CraftBrewings {
    width: 6.3rem;
  }


  .CraftBrewingCarousel {
    width: 20.625rem;
    height: 10rem;
    margin-left: 2rem;


    .Carousels {
      height: 10rem;

      .wine {
        width: 2.625rem;
        display: flex;
        align-items: center;
      }


      .contentImg {
        width: 14.375rem;
      }


    }
  }

  .CraftBrewingCarousel:not(:last-child)::after {
    margin-right: 3rem;
  }
}

@media screen and (max-width: 576px) and (-webkit-min-device-pixel-ratio: 2) {
  .CraftBrewingCarousel:last-child {
    margin-right: 36px !important; /* 用PX单位设置 */
  }
}

