.Carousel {
    width: 56.25%;
    margin: 5rem auto 0;




}



.medal {
    //display: block;
    width: 56.25%;
    height: 19.845rem;

    .dWidth {
        width: 2rem;
        height: 2rem;
    }

    .bWidth {
        width: 20rem;
        height: 20rem;
    }
}

.medalMp {
    display: none;
}

.stepMp {
    display: none;
}

.dotsClass {
    .slick-active {
        button {
            background: #F8A03F;
        }

    }

    :where.slick-dots{
        z-index: 9 !important;
    }
    li {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
    }
    button {
        width: 20px !important;
        height: 20px !important;
        border-radius: 50% !important;
        background: transparent !important;
        border: 2px solid #F8A03F !important;
    }

    button::after {
        inset: unset ;
    }

}


.brandIntroductionLogo {
    margin-top: 3.625rem;

    img {
        margin: 0 auto;
    }

    .brandIntroductionLine {
        width: 10.125rem;
        height: 0.1875rem;
        background: #F8A03F;
        margin: 0.875rem auto 0;
    }

    .introduction {
        margin-top: 0.9375rem;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        color: #F8FAE4;

        p {
            line-height: 2.375rem;
        }
    }
}

.fadeInLeft {
    width: 74rem;
    height: 12.3125rem;
    background: #F8A03F;
    border-radius: 0 6.25rem 6.25rem 0;

    .fadeInLeftSon {
        animation-delay: 0.5s;
        margin-left: 29.875rem;



        p {
            width: 38.1875rem;
            height: 2.9375rem;
            color: #121D4E;
        }
    }

    .hourglass {
        //width: 6.25rem;
        //height: 6.25rem;
        width: 3.75rem;
        height: 3.75rem;
    }

    .historyWord {
        //width: 5.25rem;
        width: 4.15rem;
    }

    .historyText {
        width: 22.8125rem;
        height: 5.5rem;
        .fromYear {
            width: 14.3125rem;
        }

        .historyYear {
            width: 12rem !important;
        }

        .GermanBrand {
            background: #F8FAE4;
            border-radius: 0.625rem;
            color: #F8A03F;
            font-weight: 500;
            font-size: 1.2rem;
            width: auto;
            padding: 0.5rem;
        }
    }
}

.fadeInRight {
    width: 74rem;
    height: 14.875rem;
    background: #F8A03F;
    border-radius: 6.25rem 0 0 6.25rem ;

    .fadeInLeftSon {
        animation-delay: 0.5s;
        margin-left: 4.6875rem;

        p {
            width: 38.1875rem;
            height: 2.9375rem;
            color: #121D4E;
        }
    }

    .hourglass {
        //width: 6.25rem;
        //height: 6.25rem;
        width: 3.75rem;
        height: 3.75rem;
    }

    .historyWord {
        //width: 5.25rem;
        width: 4.15rem;
    }

    .historyText {
        //width: 26.8125rem;
        //width: 13.69rem;
        height: 5.5rem;

        .fromYear {
            width: 14.3125rem;
        }

        .historyYear {
            width: 15.25rem;
        }

        .GermanBrand {
            background: #F8FAE4;
            border-radius: 0.625rem;
            color: #F8A03F;
            font-weight: 500;
            font-size: 1.2rem;
            width: auto;
            padding: 0.5rem;
        }
    }
}

.stepOne {
    width: 13.75rem;
    height: 16.125rem;
    background-image: url("../../assets/images/1.svg") ;
    background-repeat: no-repeat;
    background-position: center;
    animation-delay: 1s;

    .history {
        margin-left: 3.8125rem;
    }

    &:local {
        animation-name: slideInRight
    }


}

.stepTwo {
    width: 13.75rem;
    height: 17.125rem;
    background-image: url("../../assets/images/2.svg") ;
    background-repeat: no-repeat;
    background-position: center;
    animation-delay: 1s;

    .history {
        //margin-left: 3.8125rem;
    }

    &:local {
        animation-name: slideInRight
    }


}

.stepThree {
    width: 13.75rem;
    height: 17.125rem;
    background-image: url("../../assets/images/3.svg") ;
    background-repeat: no-repeat;
    background-position: center;
    animation-delay: 1s;

    .history {
        margin-left: 3.8125rem;
    }

    &:local {
        animation-name: slideInRight
    }


}

.stepOneM {
    display: none;
}
.stepThreeM {
    display: none;
}

.stepTwoM {
    display: none;
}

@keyframes slideInRight {
    0% {
        opacity: 0 ;
    }

    100% {
        opacity: 1 ;
    }
}

@media screen and (max-width: 576px) {
    .stepMp {
        display: block;
    }

    .stepWeb {
        display: none;
    }
    .Carousel {
        width: 87%;
        margin: 2.125rem auto 0;




    }

    .brandIntroduction {
        width: 6.875rem;
        margin: 0 auto;
    }
    .brandIntroductionLogo {
        .brandIntroductionLine {
            width: 4.375rem !important;
            height: 0.1875rem;
            background: #F8A03F;
            margin: 0.875rem auto 0;
        }
    }

    .brandIntroductionEn {
        width: 6.1875rem;
        margin: 0 auto;
    }


    .dotsClass {
        .slick-active {
            button {
                background: #F8A03F;
            }

        }
        li {
            width: 6px !important;
            height: 6px !important;
            border-radius: 50% !important;
        }
        button {
            width: 6px !important;
            height: 6px !important;
            border-radius: 50% !important;
            background: transparent !important;
            border: 2px solid #F8A03F ;
        }

        button::after {
            inset: unset;
        }

    }

    .fadeInLeft {
        width: 22.625rem;
        height: 21.625rem;
        border-radius: 0 165px 165px 0;
        position: relative;

        .hourglass {
            width: 4rem;
            height: 4rem;
        }

        .historyWord {
            width: 4rem;
        }

        .historyText {
            margin-left: 1.8125rem;
            //width: 22.8125rem;
            //height: 5.5rem;
            .fromYear {
                width: 9.1875rem !important;
            }

            .historyYear {
                width: 9.6875rem !important;
            }

            .GermanBrand {
                background: #F8FAE4;
                border-radius: 0.625rem;
                color: #F8A03F;
                font-weight: 500;
                font-size: 0.875rem !important;
                min-width: 4.5rem;
                width: auto;
            }
        }

        .fadeInLeftSon {
            animation-delay: 0.5s;
            margin-left: 1.8125rem;
            margin-top: 5px;

            //width: 13rem;
            //height: 8.5rem;
            display: flex;
            flex-direction: column;

            .fadeInLeftSonDom {
                width: 100%;
                margin-bottom: 0.75rem;
            }

            p {
                width: 13rem;
                height: 2.9375rem;
                color: #121D4E;
            }
        }
    }

    .fadeInRight {
        width: 22.625rem;
        height: 22.625rem;
        border-radius: 175px 0 0 175px ;
        position: relative;

        .fadeInLeftSon {
            animation-delay: 0.5s;
            margin-left: 7.6875rem;
            //margin-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .fadeInLeftSonDom {
                width: 100%;
                margin-bottom: 0.75rem;
            }

            p {
                width: 14.3rem;
                height: 2.9375rem;
                font-size: 12px;
                color: #121D4E;

            }
        }

        .hourglass {
            width: 4rem;
            height: 4rem;
        }

        .historyWord {
            width: 4rem;
        }

        .historyWord {
            width: 5.25rem;
        }

        .historyText {
            width: 100%;
            //height: 5.5rem;
            //float: right;

            .fromYear {
                width: 14.3125rem;
            }

            .historyYear {
                width: 15.25rem;
            }

            .GermanBrand {
                min-width: 5.625rem;
                width: auto;
                background: #F8FAE4;
                border-radius: 0.625rem;
                color: #F8A03F;
                font-weight: 500;
                font-size: 0.8rem !important;
            }
        }
    }




    .stepOne {
        display: none;
    }

    .stepTwo {
        display: none;
    }

    .stepThree {
        display: none;
    }

    .stepOneM {
        display: block;
        position: absolute;
        right: 1.75rem;
        top: 4.375rem;
    }

    .stepThreeM {
        display: block;
        position: absolute;
        right: 1.75rem;
        top: 4.375rem;
    }
    .stepTwoM {
        display: block;
        position: absolute;
        left: 1.75rem;
        top: 4.375rem;
    }

    .medal {
        display: none;
        width: 100%;
        height: 6.9rem;
        margin-top: 2.125rem;

        .mWidth {
            width: 6.75rem;
            height: 6.75rem;
        }
    }

    .medalMp {
        display: block;
    }


}


