.navs {
    color: #F8A03F;
    width: 6.5rem;
    font-size: 20px;
}



.mbTab {
    display: none;
}
.mbList {
    display: none;
}

.navList {
    margin-left: 1.81rem;
}

.navsActive {
    font-weight: 600;
}

.isActive {
    /* BR_Brand2/00 */

    background: #F8A03F;
    /* BR_Brand2/00 */

    border: 1.5px solid #F8A03F;

    margin: 0 auto;
}

.background {
    /* VI/gradient */

    background: linear-gradient(90deg, #1A254A 0%, #233E8E 100%);
}

.pcHeader {
    display: block;

}



.mobileHeader {
    display: none;
}

:global  {
    :where.ant-affix {
        z-index: 999 !important;
    }

}

@media screen and (max-width: 576px) {
    .pcHeader {
        display: none;
    }

    .mobileHeader {
        display: block;
    }

    .mbTab {
        display: block;
        position: fixed;
        right: 20px;
        top: 88px;
        z-index: 99999;
    }

    .mbList {
        width: 336px;
        height: 128px;
        position: fixed;
        background: #F8FAE4;
        right: 20px;
        top:128px;
        z-index: 999999;


    }

    .mbTabs {
        height: 32px;
        display: flex;
        justify-content: center;
        align-content: center;
        color: #8A8B92;
        width: 100%;
    }

    .ac {
        background: #F8A03F;
        color: #F8FAE4;
        font-weight: 600;
        box-shadow: 0px 2px 0px #121D4E;

    }
}

